import React from 'react';
import { Document, Page, Text, View, Svg, Path } from '@react-pdf/renderer';
import { Routes } from '../../../types/Routes';

import { styles } from './styles';

type FileProps = {
  routes: Routes[];
};

const File: React.FC<FileProps> = ({ routes }) => {
  let count = 0;
  return (
    <Document>
      <Page size="A4" style={styles.page} orientation="portrait">
        {routes
          .sort((a: Routes, b: Routes) =>
            a.routeNum.localeCompare(b.routeNum, undefined, { numeric: true }),
          )
          .map((route) =>
            route.turns.map((turn) => {
              let names: string[] = [];
              if (turn.household) {
                names = turn.household.name.toUpperCase().trim().split(' ');
              }
              if (count === 5) {
                count = 2;
              } else {
                count += 1;
              }
              return (
                <View style={styles.tag_box} key={turn.id} break={count === 5}>
                  <Text style={styles.tag_route_text}>
                    ROTA: {route.routeNum}
                  </Text>
                  <View
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                      width: '100%',
                      justifyContent: 'center',
                    }}
                  >
                    {turn.household?.market_session && (
                      <Svg
                        style={{
                          width: '20px',
                          height: '20px',
                          marginRight: '2px',
                        }}
                        viewBox="0 0 24 24"
                      >
                        <Path d="M0 0h24v24H0V0z">
                          <Path
                            fill="#007970"
                            d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2zm-1.45-5c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6z"
                          />
                        </Path>
                      </Svg>
                    )}
                    <Text
                      style={{ ...styles.tag_route_householder, width: 'auto' }}
                    >
                      {names[0]} {names.length > 1 && names[names.length - 1]}
                    </Text>
                  </View>
                  <View style={styles.tag_route_householder_num_box}>
                    <Text style={styles.tag_route_householder_num_text}>
                      {turn.household &&
                        turn.household.household_members &&
                        turn.household.household_members.length + 1}
                    </Text>
                  </View>
                  <View style={styles.tag_turn_tags_left}>
                    {turn.tags &&
                      turn.tags.length > 0 &&
                      turn.tags
                        .filter(
                          (t) =>
                            t.name.toLocaleLowerCase().includes('manteiga') ||
                            t.name.toLocaleLowerCase().includes('iogurte') ||
                            t.name.toLocaleLowerCase().includes('carne'),
                        )
                        .map((tag) => (
                          <Text style={styles.tag_turn_tags_text} key={tag.id}>
                            {tag.name}
                          </Text>
                        ))}
                  </View>
                  <View style={styles.tag_turn_tags}>
                    {turn.tags &&
                      turn.tags.length > 0 &&
                      turn.tags
                        .filter(
                          (t) =>
                            t.name.toLocaleLowerCase().includes('fruta') ||
                            t.name.toLocaleLowerCase().includes('legumes') ||
                            t.name.toLocaleLowerCase().includes('pão'),
                        )
                        .map((tag) => (
                          <Text style={styles.tag_turn_tags_text} key={tag.id}>
                            {tag.name}
                          </Text>
                        ))}
                  </View>
                  <View style={styles.tag_route_info}>
                    <Text style={styles.tag_route_info_text}>
                      {turn.canCook ? `COZINHA` : 'NÃO COZINHA'}
                    </Text>
                    <Text style={styles.tag_route_info_text}>
                      {turn.note && `NOTAS: ${turn.note.toUpperCase()}`}
                      {turn.onceMonth && '(1 vez mês)'}
                    </Text>
                  </View>
                </View>
              );
            }),
          )}
      </Page>
    </Document>
  );
};

export default File;
